import React from "react";

import Addposter from "../../assets/images/Home/Choosesec/addposter.png";
import { Col, Row } from "react-bootstrap";
const Choosekansensus = () => {
  return (
    <>
      <div className="choose-kansensus-component container">
        <Row>
          <Col className="choose-kansensus-details mt-5" xs={12} md={12} lg={6}>
            <div className="content mt-1">
              <h5>Why Choose kansensus</h5>
              <h1>
                Empower Your Voice and <span>Influence Change</span>
              </h1>
              <div className="para mt-3">
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque labore et dolore magna aliqua. Ut enim
                  ad minim veniam laudantium, totam rem aperiam, eaque ipsa quae
                  ab illo inventore.
                </p>
                <br />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </Col>
          <Col
            className="choose-kansensus-image mt-5"
            xs={12}
            md={12}
            lg={{ span: 4, offset: 2 }}
          >
            <img src={Addposter} />
          </Col>
        </Row>
      </div>
    </>
  );
};
export { Choosekansensus };
