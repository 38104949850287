import React from "react";

const Video = () => {
  return (
    <div className="container">
      <div class="embed-responsive embed-responsive-21by9">
        <iframe
          className="iframe-video"
          src="https://votingapp.trangotechdevs.com/voting_video.mp4"
          allowFullScreen
          allowTransparency
          alt=""
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
