import React from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { VALIDATIONS, VALIDATIONS_TEXT } from "../../utils/Validations";
import CustomInput from "../Defaut/CustomInput";

const JoinWaitlist = () => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <div className="joinwaitlist-component container" id="about-us">
        <h1>Join the waitlist Today</h1>
        <h5>Sign Up for the waitlist now and get 100 Points</h5>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-3 email">
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                },
                maxLength: {
                  value: VALIDATIONS.EMAIL,
                  message: VALIDATIONS_TEXT.EMAIL,
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                },
              }}
              render={({ field }) => (
                <div className="input-wrapper">
                  <CustomInput
                    {...field}
                    label="Enter Email"
                    placeholder="Enter Email"
                    type="email"
                    className="name"
                    required={true}
                    maxLength={VALIDATIONS.EMAIL}
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <button type="button" className="email-button">
                    Join Waitlist
                  </button>
                </div>
              )}
            />
            {/* {errors.email && <small className='text-start d-block text-danger'>{errors.email.message}</small>} */}
          </div>
          <Button type="submit" className="email-button-mobile">
            Join Waitlist
          </Button>
        </Form>
      </div>
    </>
  );
};
export { JoinWaitlist };
