import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";

import mobile from "../../assets/images/Home/verticalmobile.png";
import card from "../../assets/images/Home/CARD.svg";
import first from "../../assets/images/Home/TopHeader/1.png";
import second from "../../assets/images/Home/TopHeader/2.png";
import third from "../../assets/images/Home/TopHeader/3.png";

const AddHeader = () => {
  return (
    <>
      <div className="add-header-component container">
        <Row>
          <Col className="head-data mt-5" xs={12} md={12} lg={6}>
            <p className="speak-up">
              Speak Up and Be Heard Vote on Important Topics and Share Your
              Opinions
            </p>
            <p className="bottom-speak ">
              Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet
              faucibus tincidunt eu adipiscing sociis arcu lorem porttitor.
            </p>
            <div className="d-flex bottom-details mt-5">
              <div className="img-three-add">
                <img src={first} className="first" />
                <img src={third} className="second" />
                <img src={second} className="third" />
              </div>
              <div className="happy-customer ">
                <div className="count">20,291</div>
                <div className="para">Happy Customers</div>
              </div>
              <div className="vertical-line"></div>
              <div className="rating-details ">
                <div className="rating-avg">4.8/5</div>
                <div className="rating-diag d-flex">
                  <Rating size={25} readonly initialValue={4} /> Rating
                </div>
              </div>
            </div>
          </Col>
          <Col
            className="ban-img d-none d-lg-block mt-5"
            md={{ span: 5, offset: 1 }}
          >
            <div className="vertical-img image-fluid">
              <img src={mobile} className="image-fluid vertical-image-css" />
              <div className="upper-image">
                <img src={card} className="upper-image-css" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { AddHeader };
